<template>
    <a-spin :spinning="loading">
        <a-card style="margin-top: 16px">
            <template #title>
                <span style="font-size: 14px">办理机构</span>
            </template>

            <a-form-model
                ref="form"
                :model="form"
                :rules="formRules"
                :label-col="{ span: 4}"
                :wrapper-col="{ span: 18 }"
                :hide-required-mark="true"
            >
                <a-row>
                    <a-col :span="12">
                        <a-form-model-item label="办理银行" prop="organizationId">
                            <financial-institution-select
                                v-model="form.organizationId"
                                v-bind="institutionSelectProp"
                                style="width: 320px;"
                                @on-select-change="handleBankSelectChange"
                            />
                        </a-form-model-item>
                    </a-col>

                    <a-col :span="12">
                        <a-form-model-item label="机构联系人">
                            {{ form.contactName | emptyFilter }}
                        </a-form-model-item>
                    </a-col>

                    <a-col :span="12">
                        <a-form-model-item label="机构联系人">
                            {{ form.contactPhone | emptyFilter }}
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
        </a-card>
    </a-spin>
</template>
<script>
import {
    Row as ARow,
    Col as ACol,
    Card as ACard,
    Spin as ASpin,
    FormModel as AFormModel
} from 'ant-design-vue'
import FinancialInstitutionSelect from '@weights/CreateForm/components/FinancialInstitutionSelect'

import { empty } from '@utils'

import { INST_TYPE_BANK } from '@constant/enum'

import getInfoService from '@service/marginManagement/marginPaymentDetail/getInstitutionInfoService'

const FORM_RULES = Object.freeze({
    organizationId: [
        {
            required: true,
            message: '此项为必填'
        }
    ]
})

const INSTITUTION_SELECT_PROP = Object.freeze({
    // 取值使用主键 id
    optionValue: 'id',

    allowClear: true,
    institutionType: INST_TYPE_BANK
})

export default {
    name: 'HandlingAgencyPanel',
    components: {
        ARow,
        ACol,
        ASpin,
        ACard,
        AFormModel,
        FinancialInstitutionSelect,
        AFormModelItem: AFormModel.Item
    },
    filters: {
        emptyFilter: empty
    },
    props: {
        orderNo: String
    },
    data() {
        return {
            form: {},
            loading: false,

            formRules: FORM_RULES,
            institutionSelectProp: INSTITUTION_SELECT_PROP
        }
    },
    watch: {
        orderNo: {
            handler(orderNo) {
                this.getInstitutionInfo(orderNo)
            },
            immediate: true
        }
    },
    methods: {
        async getInstitutionInfo(orderNo) {
            try {
                this.loading = true

                // eslint-disable-next-line
                const info = await getInfoService({ orderNo }) || {}

                this.form = {
                    organizationId: info.id,
                    contactName: info.contactName,
                    contactPhone: info.contactPhone
                }
            } catch (e) {
                this.$message.warning(e.message)
            } finally {
                this.loading = false
            }
        },
        validate() {
            return new Promise((resolve, reject) => {
                this.$refs.form.validate(valid => {
                    valid ? resolve(this.form) : reject()
                })
            })
        },
        handleBankSelectChange(option = {}) {
            this.$set(this.form, 'contactName', option.contactName)
            this.$set(this.form, 'contactPhone', option.contactPhone)
        }
    }
}
</script>
