import axios from '@service'
import { APPLY_BANK_GUARANTEE_HANDLING } from './api'
import { HTTP_ERR_SUCCESS } from '@constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@constant/error'

// 申请银行保函申请
export default async function applyBankGuaranteeHandlingService(payload = {}, config = {}) {
    const param = {
        ...APPLY_BANK_GUARANTEE_HANDLING,
        ...config,
        data: payload
    }

    const { data } = await axios(param)

    if (data.code === HTTP_ERR_SUCCESS) {
        return data.result || {}
    } else {
        throw new Error(data.errorMessage || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
